@media (orientation: portrait) {
  .app-top-bar {
    padding-top: calc(env(safe-area-inset-top)  - 12px);
  }

  .app-bottom-bar {
    padding-bottom: calc(env(safe-area-inset-bottom)  - 12px);
  }
}

@media (orientation: landscape) {
  .app-bottom-bar {
    padding-bottom: calc(env(safe-area-inset-bottom));
  }

  .app-content-container {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.nav-button-active, .nav-button-active.nav-button-focus {
  background-color: #ffffff40;
}

.snack-root {
  bottom: calc(env(safe-area-inset-bottom)  + 48px) !important;
}
/*# sourceMappingURL=index.cdd72709.css.map */
