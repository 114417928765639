.app-top-bar {
  @media (orientation: portrait) {
    padding-top: calc(env(safe-area-inset-top) - 12px);
  }
}

.app-bottom-bar {
  @media (orientation: portrait) {
    padding-bottom: calc(env(safe-area-inset-bottom) - 12px);
  }
  @media (orientation: landscape) {
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
}

.app-content-container {
  @media (orientation: landscape) {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.nav-button-active,
.nav-button-active.nav-button-focus {
  background-color: rgba(255, 255, 255, 0.25);
}

.snack-root {
  bottom: calc(env(safe-area-inset-bottom) + 48px) !important;
}
